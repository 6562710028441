// @import "../node_modules/bootstrap/dist/css/bootstrap-grid.min.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*,
*::before,
*::after 
{
  box-sizing: border-box;
}

* 
{
  margin: 0;
  padding: 0;
}

html
{
  scroll-behavior: smooth;
  min-height: 100%;
  background: url(../public/images/background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

body
{
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.container
{
  padding: 0 10%;
}

// .w-100
// {
//   width: max-content;
// }

ul
{
  list-style: none;
}

a
{
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid #fff;
}

.btn-download
{
  padding: 8px 16px;
  border: 1px solid #fff;
  border-radius: 50px;
  font-size: 15px;
  transition: all 0.3s ease;

  &:hover
  {
    color: #000;
    background-color: #fff;
  }
}

.icon-contact
{
  width: 20px;
}

.icon-skill
{
  width: 100px;
}

.portfolio-desc
{
  background-color: #17202A;
}

@keyframes fadeInUp
{
  0%
  {
    transform: translateY(100%);
    opacity: 0;
  }

  100%
  {
    transform: translateY(0%);
    opacity: 1;
  }
}

.fadeInUp-animation
{
  animation: 1.5s fadeInUp;
}

.fade-in-section
{
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible
{
  opacity: 1;
  transform: none;
  visibility: visible;
}



// img
// {
//   display: block;
//   max-width: 100%;
// }

// p, h3, h4, h5
// {
//   font-family: "Nunito", sans-serif !important;
// }

// .container
// {
//   margin-top: 3rem;
// }

// .card
// {
//   width: 500px;
//   border-radius: 0.5rem;
//   background-color: #fff;
// }

// .card-img
// {
//   // width: 70%;
// }

// .card-body
// {
//   padding: 1rem;
// }

// .card-title
// {
//   font-size: 20px;
// }